var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}}),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}})],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Manage Portal Access "),_c('router-link',{staticClass:"ml-4",attrs:{"to":("/customers/" + _vm.customerId + "/manage-portal-access/newUser")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("New User")])],1),_c('router-link',{staticClass:"ml-4",attrs:{"to":("/customers/" + _vm.customerId + "/manage-portal-access/addYnMember")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("Add YN Member")])],1)],1),_c('v-card-text',[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","variant":"outlined","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.members,"search":_vm.search,"item-key":"id","loading":_vm.isLoading,"items-per-page":-1,"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.communities",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCommunitiesString(item.communities.$values))+" ")]}},{key:"item.password",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"orange white--text",attrs:{"small":"","text":""},on:{"click":function($event){$event.stopPropagation();item.changePasswordDialog = true}}},[_vm._v(" Change ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(item.changePasswordDialog),callback:function ($$v) {_vm.$set(item, "changePasswordDialog", $$v)},expression:"item.changePasswordDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Change Password ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"New Password","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":_vm.passwordErrors,"required":""},on:{"click:append":function($event){$event.preventDefault();_vm.showPassword = !_vm.showPassword},"input":function($event){return _vm.$v.password.$touch()},"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('p',[_vm._v(" Password must be minimum of eight characters with at least one capital and one lower case letter. ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Confirm Password","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":_vm.passwordErrors,"rules":[
                        _vm.password === _vm.confirmPassword ||
                          'Passwords do not match'
                      ],"required":""},on:{"click:append":function($event){$event.preventDefault();_vm.showPassword = !_vm.showPassword},"input":function($event){return _vm.$v.confirmPassword.$touch()},"blur":function($event){return _vm.$v.confirmPassword.$touch()}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.statusProcessing)?_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.changePasswordDialog = false}}},[_vm._v(" Cancel ")]):_vm._e(),_c('v-btn',{attrs:{"loading":_vm.statusProcessing,"color":"green darken-1","text":""},on:{"click":function($event){return _vm.changePassword(item, _vm.password, _vm.confirmPassword)}}},[_vm._v(" Change ")])],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToEitUser(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();item.deleteDialog = true}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete User Access ")]),_c('v-card-text',[_vm._v("Are you sure you want to delete user access: "),_c('p',[_vm._v(_vm._s(item.fullName))])]),_c('v-card-actions',[_c('v-spacer'),(!_vm.statusProcessing)?_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(" Cancel ")]):_vm._e(),_c('v-btn',{attrs:{"loading":_vm.statusProcessing,"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteUserAccess(item)}}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }