<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
    </v-skeleton-loader>
    <v-card elevation="0">
      <v-card-title>
        Manage Portal Access
        <router-link
          class="ml-4"
          :to="`/customers/${customerId}/manage-portal-access/newUser`"
        >
          <v-btn class="orange-bg white--text" small>New User</v-btn>
        </router-link>
        <router-link
          class="ml-4"
          :to="`/customers/${customerId}/manage-portal-access/addYnMember`"
        >
          <v-btn class="orange-bg white--text" small>Add YN Member</v-btn>
        </router-link>
      </v-card-title>
      <v-card-text>
        <!-- Start Manage Portal Access section -->
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
          class="mb-2"
        >
        </v-text-field>
        <v-data-table
          :headers="headers"
          :items="members"
          :search="search"
          item-key="id"
          :loading="isLoading"
          
          :items-per-page="-1"
          :options.sync="options"
          loading-text="Loading... Please wait"
          class="elevation-1"
        >
          <template #[`item.role`]="{ item }">
            {{ item.role }}
          </template>
          <template #[`item.communities`]="{ item }">
            {{ getCommunitiesString(item.communities.$values) }}
          </template>
          <template #[`item.password`]="{ item }">
            <v-btn
              small
              class="orange white--text"
              text
              @click.stop="item.changePasswordDialog = true"
            >
              Change
            </v-btn>

            <v-dialog
              v-model="item.changePasswordDialog"
              persistent
              max-width="290"
            >
              <v-card>
                <v-card-title class="headline">
                  Change Password
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="password"
                        label="New Password"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append.prevent="showPassword = !showPassword"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="passwordErrors"
                        @input="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        required
                      ></v-text-field>
                      <p>
                        Password must be minimum of eight characters with at
                        least one capital and one lower case letter.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="confirmPassword"
                        label="Confirm Password"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append.prevent="showPassword = !showPassword"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="passwordErrors"
                        @input="$v.confirmPassword.$touch()"
                        @blur="$v.confirmPassword.$touch()"
                        :rules="[
                          password === confirmPassword ||
                            'Passwords do not match'
                        ]"
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!statusProcessing"
                    color="green darken-1"
                    text
                    @click="item.changePasswordDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="statusProcessing"
                    color="green darken-1"
                    text
                    @click="changePassword(item, password, confirmPassword)"
                  >
                    Change
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn class="ma-2" text icon @click.stop="goToEitUser(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              text
              icon
              @click.stop="item.deleteDialog = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-dialog v-model="item.deleteDialog" persistent max-width="290">
              <v-card>
                <v-card-title class="headline">
                  Delete User Access
                </v-card-title>
                <v-card-text
                  >Are you sure you want to delete user access:
                  <p>{{ item.fullName }}</p></v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!statusProcessing"
                    color="green darken-1"
                    text
                    @click="item.deleteDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="statusProcessing"
                    color="green darken-1"
                    text
                    @click="deleteUserAccess(item)"
                  >
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="item.statusDialog" persistent max-width="290">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text
                  >Are you sure you want to
                  {{ item.isActive ? "suspend" : "resume" }} access for
                  {{ item.fullName }}
                  ?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!statusProcessing"
                    color="green darken-1"
                    text
                    @click="item.statusDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="toggleStatus(item)"
                    :loading="statusProcessing"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
            <!-- <v-tooltip bottom>
              <template #activator="{on, attrs}">
                <v-btn
                  small
                  class="white--text "
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="item.statusDialog = true"
                  :class="item.isActive ? 'orange' : 'blue'"
                >
                  {{ item.isActive ? "Suspend" : "Resume" }}
                </v-btn>
              </template>
              <span>{{ item.isActive ? "Suspend" : "Resume" }} Access</span>
            </v-tooltip> -->
          </template>
        </v-data-table>

        <!-- End Manage Portal Access section -->
      </v-card-text>
    </v-card>
    <!-- </v-container> -->
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  POST_DATA,
  PUT_DATA,
  DELETE,
  API_CUSTOMERS,
  API_USER_INFO
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: -1,
      sortBy: ["fullName"],
      sortDesc: [false]
    },
    statusProcessing: false,
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "fullName",
        class: "orange-bg"
      },
      { text: "Role", value: "role", class: "orange-bg" },
      { text: "Email", value: "email", class: "orange-bg" },
      { text: "Communities", value: "communities", class: "orange-bg" },
      {
        text: "Password",
        value: "password",
        class: "orange-bg",
        sortable: false
      },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    members: [],
    totalRecords: null,
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    permissions: {},
    showPassword: false,
    password: "",
    confirmPassword: ""
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return {
      password: {
        required
      },
      confirmPassword: {
        required
      }
    };
  },
  watch: {
    options: async function() {
      await this.getEmployees();
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.loading = false;
    this.getEmployees();
  },
  async created() {
    permissionsHelper.getPermissions().then(this.getPermissions);
    await this.getComunityInfo();
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    goToEitUser(userItem) {
      this.$router.push({
        name: "ManagePortalAccessEditUser",
        params: { customerId: this.customerId, userId: userItem.id }
      });
    },
    async changePassword(item, password, newPassword) {
      if (password != newPassword) {
        this.$snackbar.showMessage({
          content: "Passwords don't match.",
          color: "red"
        });
        return;
      }

      let updatePasswordInfo = {
        identityId: item.id,
        password: newPassword
      };

      let self = this;
      await this.$store
        .dispatch(PUT_DATA, {
          listName: `${API_USER_INFO}/${item.id}/password`,
          data: updatePasswordInfo
        })
        .then(response => {
          // if (response && (response.status >= 200 || response.status <= 204))
          if (response) {
            self.$snackbar.showMessage({
              content: "Password has been updated.",
              color: "green"
            });
            self.password = "";
            self.confirmPassword = "";
            item.changePasswordDialog = false;
          }
        });
    },
    async getComunityInfo() {
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Manage Portal Access" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Manage Portal Access" }
      ]);
    },
    deleteUserAccess(item) {
      let self = this;
      if (!item) return;

      this.statusProcessing = true;

      this.$store
        .dispatch(DELETE, {
          listName: `${API_CUSTOMERS}/${self.customerId}/user`,
          id: item.id
        })
        .then(function() {
          self.statusProcessing = false;
          item.deleteDialog = false;
          self.getEmployees();
        });
    },
    toggleStatus(item) {
      if (this.statusProcessing) return;

      this.statusProcessing = true;

      // item.isActive = !item.isActive;

      let url = `${API_USER_INFO}/${item.id}/${
        item.isActive ? "suspend" : "activate"
      }`;
      this.$store
        .dispatch(POST_DATA, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            item.isActive = response.data.isActive;
            this.$snackbar.showMessage({
              content: "Member has been updated"
            });
          }
          this.statusProcessing = false;
          item.statusDialog = false;
        });
    },
    async getEmployees() {
      this.isLoading = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${this.customerId}/users`
          // ,
          // params: {
          //   // query: this.userQuery,
          //   // skip: this.options.itemsPerPage * (this.options.page - 1),
          //   // take:
          //   //   this.options.itemsPerPage === -1
          //   //     ? this.totalUsers
          //   //     : this.options.itemsPerPage,
          //   // sort: this.sort
          // }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const { sortBy, sortDesc } = this.options;

            let items = response.data.$values;
            items.forEach(element => {
              element.statusDialog = false;
              element.deleteDialog = false;
              element.changePasswordDialog = false;
              element.editCommunitiesDialog = false;
              element.permissionsDialog = false;
              if (!element.role) element.role = { $values: [] };
            });

            const total = items.length;

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            // if (itemsPerPage > 0) {
            //   items = items.slice(
            //     (page - 1) * itemsPerPage,
            //     page * itemsPerPage
            //   );
            // }

            this.members = items;
            this.totalRecords = total;
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive a list of users. Something went wrong!",
              color: "red"
            });
          }
          this.isLoading = false;
        });
    },
    getRolesString(roles) {
      let rolesStr = "";
      for (let i = 0; i < roles.length; i++) {
        rolesStr += roles[i].name + "; ";
      }
      if (rolesStr === "") return "---";
      // remove last "; "
      rolesStr = rolesStr.substring(0, rolesStr.length - 2);
      return rolesStr;
    },
    getCommunitiesString(communities) {
      let communityStr = "";
      for (let i = 0; i < communities.length; i++) {
        communityStr += communities[i] + "; ";
      }
      if (communityStr === "") return "---";
      // remove last "; "
      communityStr = communityStr.substring(0, communityStr.length - 2);
      return communityStr;
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment.utc(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Required.");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
